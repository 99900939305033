import { render, staticRenderFns } from "./CateringAndLocations.vue?vue&type=template&id=f2a6bfa4&"
import script from "./CateringAndLocations.vue?vue&type=script&lang=js&"
export * from "./CateringAndLocations.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TitleRow: require('/codebuild/output/src1179806432/src/eddys/components/TitleRow.vue').default,SubTitleRow: require('/codebuild/output/src1179806432/src/eddys/components/SubTitleRow.vue').default,ResponsivePicture: require('/codebuild/output/src1179806432/src/eddys/components/global/ResponsivePicture.vue').default,ComponentBlockSingleColumnOptions: require('/codebuild/output/src1179806432/src/eddys/components/component/block/SingleColumnOptions.vue').default,ComponentBlockThreeColumnsMap: require('/codebuild/output/src1179806432/src/eddys/components/component/block/ThreeColumnsMap.vue').default,SectionLayoutWrapper: require('/codebuild/output/src1179806432/src/eddys/components/global/SectionLayoutWrapper.vue').default})
